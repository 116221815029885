import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import BulletSection from 'components/shared/BulletSection';
import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import imageList from '../config/images';
import Layout from 'components/Layout';
import MajorSection from 'components/shared/MajorSection';
import paths from '../config/paths';
import React from 'react';
import seo from 'config/seo.json';
import Video from 'components/shared/Video';
import videoList from 'config/videos';

export default class Agents extends React.Component {
	render() {
		const numbersList = content.pages.agents.numbers.directionals.map(
			(number, index) => (
				<li key={index}>
					<h4 className="font-semibold mb-8 text-primary text-5xl">
						{number.title}
					</h4>
					<p>{number.content}</p>
				</li>
			)
		);

		return (
			<Layout
				title={seo.agents.title}
				description={seo.agents.description}
			>
				<Hero
					alt={imageList.agents.alt}
					backgroundColor="secondary"
					buttonText={content.pages.agents.hero.button}
					buttonUrl={paths.register.agent}
					content={content.pages.agents.hero.content}
					image={getImage(this.props.data.file)}
					isExternalLink={true}
					paddingBottom="12"
					title={content.pages.agents.hero.title}
				/>
				<BulletSection
					title={content.pages.agents.about.title}
					content={content.pages.agents.about.content}
					listOne={content.pages.agents.about.infoListOne}
					listTwo={content.pages.agents.about.infoListTwo}
				/>
				<section className="bg-secondary bg-opacity-10">
					<div className="flex flex-col items-center max-w-7xl mx-auto px-8 lg:px-16 py-12">
						<h3 className="font-semibold mb-12 text-primary text-3xl">
							{content.pages.agents.numbers.title}
						</h3>
						<ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 mb-12">
							{numbersList}
						</ul>
						<p className="text-primary font-semibold">
							{content.pages.agents.numbers.content}
						</p>
					</div>
					<MajorSection>
						<Video
							height="576"
							src={videoList.agent}
							width="1024"
						/>
					</MajorSection>
				</section>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "hero-real-estate-agents.png" }) {
			id
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
